<template>
  <div id="carrello">
    <!-- <h1>CARRELLO</h1> -->
    <div class="cerca-modello">
      <el-input
        v-model="cerca"
        :placeholder="$t('message.Cerca_modello')"
        suffix-icon="el-icon-search"
      ></el-input>
    </div>
    <div class="tabella-carrello">
      <!-- v-if="windowWidth < 992 && showFullTable == false" -->
      <el-table
        class="cart-table"
        :data="pagedCartRows"
        border
        stripe
        :height="heightTableBody"
        max-height="50vh"
        :row-style="{ height: '60px' }"
        :empty-text="$t('message.Nessuna_configurazione')"
      >
        <!-- <el-table-column type="index" ></el-table-column> -->
        <el-table-column
          prop="codice_modello"
          :label="$t('message.Modello').toUpperCase()"
          align="center"
        ></el-table-column>
        <el-table-column :label="$t('message.Calibro').toUpperCase()" align="center">
          <template slot-scope="scope">
            <span>{{ scope.row.calibro }}/{{ scope.row.naso }}</span>
          </template>
        </el-table-column>
        <el-table-column :label="$t('message.colore_frontale').toUpperCase()" align="center">
          <template slot-scope="scope">
            <span>{{ scope.row.colore_frontale.replace("PL", "") }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="finishing"
          :label="$t('message.finitura').toUpperCase() + ' ' + $t('message.frontale').toUpperCase()"
          align="center"
        >
          <template slot-scope="scope">
            <span v-if="scope.row.finishing == 'S'">{{ $t("message.lucido").toUpperCase() }}</span>
            <span v-else-if="scope.row.finishing == 'N'">{{$t('message.Naturale') | upperCase}}</span>
            <span v-else>{{ $t("message.opaco").toUpperCase() }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="nome_asta"
          :label="$t('message.Aste') | upperCase"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="FinishingTemple"
          :label="$t('message.finitura').toUpperCase() + ' ' + $t('message.Aste').toUpperCase()"
          align="center"
        >
          <template slot-scope="scope">
            <span v-if="scope.row.FinishingTemple == 'S'">{{ $t("message.lucido").toUpperCase() }}</span>
            <span v-else>{{ $t("message.opaco").toUpperCase() }}</span>
          </template>
        </el-table-column>
        <!-- <el-table-column prop="nome" label="PERSONALIZZAZIONE" align="center"></el-table-column> -->
        <el-table-column :label="$t('message.Incisione').toUpperCase()" align="center">
          <template slot-scope="scope">
            <el-button
              v-if="
                scope.row.nome != '' ||
                scope.row.nomeExtAstaSx != '' ||
                scope.row.nomeIntAstaSx != '' ||
                scope.row.nomeExtAstaDx != '' ||
                scope.row.nomeIntAstaDx != ''
              "
              @click="personaliz(scope.row.id)"
              type="text"
              size="medium"
              style="color: #038EAA !important"
              >{{$t('message.Visualizza').toUpperCase()}}</el-button
            >
            <span v-else style="color: #038EAA !important">NO</span>
            <!-- <el-button
              v-else
              type="text"
              size="small"
              style="color: #038EAA !important"
            >NO</el-button>-->
          </template>
        </el-table-column>
        <!-- <el-table-column label="COLORE PERSONALIZZAZIONE" align="center"> -->
        <!-- <template slot-scope="scope">{{ scope.row.colore_nome.toUpperCase() }}</template>
        </el-table-column>-->
        <!-- <el-table-column label="LENTI" align="center" min-width="150">
          <template slot-scope="scope">
            <el-button
              v-if="scope.row.lensleft != '' && scope.row.lensright != '' || scope.row.lenssun != ''"
              @click="setDettaglioOrdineLente(scope.row)"
              type="text"
              size="medium"
              style="color: #038EAA !important"
            >{{$t('message.Visualizza').toUpperCase()}}</el-button>
            <span v-else style="color: #038EAA !important">NO</span>
          </template>
        </el-table-column> -->
        <el-table-column
          prop="note"
          :label="$t('message.Riferimento').toUpperCase()"
          align="center"
          min-width="150"
        ></el-table-column>
        <el-table-column
          prop="quantita"
          :label="$t('message.Quantità').toUpperCase()"
          align="center"
        ></el-table-column>
        <el-table-column :label="$t('message.Elimina') | upperCase" align="center">
          <template slot-scope="scope">
            <el-button
              @click="checkCancRiga(scope.row.id)"
              type="text"
              size="medium"
              style="color: #038EAA !important"
              >{{$t('message.Elimina') | upperCase}}</el-button
            >
          </template>
        </el-table-column>
      </el-table>

      <div class="pagination">
        <el-pagination
          background
          @size-change="setSizePage"
          @current-change="setPage"
          :current-page.sync="page"
          :page-sizes="pageSizes"
          :page-size="this.pageSize"
          layout="sizes, jumper, total, prev, next"
          :total="this.cartRows.length"
        ></el-pagination>
      </div>

      <el-dialog
        class="details-dialog"
        title="PERSONALIZZAZIONI"
        :visible.sync="persDialog"
        :show-close="false"
        :modal="false"
        width="75%"
      >
        <el-table
          class="history-table"
          :data="pers"
          border
          stripe
          :empty-text="$t('message.Nessuna_configurazione')"
          max-height="500"
          :row-class-name="getRowClass"
        >
          <!-- :row-style="{fontFamily:  isEmpty(fontTesto) ? fontTesto: 'Batang'}" -->
          <el-table-column
            prop="label"
            label="POSIZIONE"
            align="center"
          ></el-table-column>
          <!-- <el-table-column prop="nome" label="TESTO" align="center"></el-table-column> -->
          <el-table-column label="TESTO" align="center">
            <template slot-scope="scope">
              <span :style="{ fontFamily: scope.row.fontTesto }">{{
                scope.row.nome
              }}</span>
            </template>
          </el-table-column>
          <el-table-column :label="$t('message.Colore') | upperCase" align="center">
            <template slot-scope="scope">{{
              scope.row.coloreTesto.toUpperCase()
            }}</template>
          </el-table-column>
        </el-table>
      </el-dialog>
    </div>

    <div class="note-text">
      <span>{{$t('message.Note')}}</span>
      <el-input
        type="textarea"
        :rows="5"
        :placeholder="$t('message.Testo') | upperCase"
        v-model="note"
      ></el-input>
    </div>

    <div class="footer-button">
      <el-button-group class="buttons-group">
        <el-button
          type="default"
          :disabled="cartRows == 0"
          class="svuota-carrello"
          @click="elimCarrello = true"
          >{{ ($t("message.Svuota").toUpperCase())}} {{($t("message.Carrello").toUpperCase()) }}</el-button
        >
        <el-button
          type="primary"
          :disabled="cartRows == 0 ||disabled"
          class="conferma-ordine"
          @click="confermaOrdine"
          >{{ $t("message.Invia_ordine") | upperCase }}</el-button
        >
      </el-button-group>
    </div>

    <!-- Dialog dettaglio lenti -->
    <el-dialog
      class="details-dialog"
      title="LENTI"
      :visible.sync="detailsLensDialog"
      :show-close="false"
      :modal="false"
      width="50%"
    >
      <el-table
        class="history-table"
        :data="dettaglioOrdineLente"
        border
        stripe
        :row-style="{ height: '60px' }"
      >
        <el-table-column
          prop="label"
          label="LENTI"
          align="center"
          min-width="150"
        ></el-table-column>
        <el-table-column
          label="TIPOLOGIA DI LENTE"
          align="center"
          min-width="150"
        >
          <template slot-scope="scope">
            <span>
              {{
                scope.row.lensModel
                  .replace("LENTE", "")
                  .replace("SOLE", "")
                  .replace("SOLID", "")
                  .replace("NON POLARIZZATA", "")
                  .replace("GRADIENT", "SFUMATA")
                  .replace("GRAD", "SFUMATA")
              }}
            </span>
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>

    <!-- Cancella riga -->
    <el-dialog
      class="confirm-dialog"
      :title="$t('message.Eliminare_il_prodotto_dal_carrello?')"
      :visible.sync="eliminaRiga"
      width="500px"
      :close-on-click-modal="false"
    >
      <el-button-group>
        <el-button
          type="default"
          class="confirm-button-1"
          @click="eliminaRiga = false"
          >{{ $t("message.Annulla") | upperCase }}</el-button
        >
        <el-button
          type="primary"
          class="confirm-button-2"
          @click="cancRiga(idRigaDaCancellare)"
          >{{ $t("message.Conferma") | upperCase }}</el-button
        >
      </el-button-group>
    </el-dialog>

    <!-- Svuota carrello -->
    <el-dialog
      class="confirm-dialog"
      :title="($t('message.Svuota').toUpperCase()) + ' ' + ($t('message.Carrello').toUpperCase())"
      :visible.sync="elimCarrello"
      width="500px"
      :close-on-click-modal="false"
    >
      <el-button-group>
        <el-button
          type="default"
          class="confirm-button-1"
          @click="elimCarrello = false"
          >{{ $t("message.Annulla") | upperCase }}</el-button
        >
        <el-button
          type="primary"
          class="confirm-button-2"
          @click="svuotaCarrello"
          >{{ $t("message.Conferma") | upperCase }}</el-button
        >
      </el-button-group>
    </el-dialog>

    <!-- Ordine inviato -->
    <el-dialog
      class="confirm-dialog"
      width="550px"
      :title="$t('message.Ordine_completato_con_successo')"
      :visible.sync="confirmAdd"
      :modal="false"
      :show-close="false"
      :close-on-click-modal="false"
    >
      <el-button-group>
        <el-button
          type="default"
          class="confirm-button-1"
          @click="goToConfigurator"
        >
          <span v-if="windowWidth < 767">{{$t('message.Configuratore').toUpperCase()}}</span>
          <span v-else>{{$t('message.Vai_al_configuratore').toUpperCase()}}</span>
        </el-button>
        <el-button type="primary" class="confirm-button-2" @click="goToStorico">
          <span v-if="windowWidth < 767">{{$t('message.storico_ordini').toUpperCase()}}</span>
          <span v-else>{{$t('message.Vai_allo_storico_ordini').toUpperCase()}}</span>
        </el-button>
      </el-button-group>
    </el-dialog>
  </div>
</template>

<script>
// import { mapGetters } from "vuex";
import jslinq from "jslinq";
import _ from "lodash";
export default {
  data() {
    return {
      note: "",
      cerca: "",
      page: 1,
      pageSize: 5,
      disabled: false,
      pageSizes: [5, 10, 20, 50, 100],
      confirmAdd: false,
      eliminaRiga: false,
      eliminaCarrello: false,
      idRigaDaCancellare: "",
      elimCarrello: false,
      persDialog: false,
      pers: [],
      windowWidth: window.innerWidth,
      detailsLensDialog: false,
      dettaglioOrdineLente: [],
    };
  },
  async mounted() {
    await this.getUserCart();
  },

  methods: {
    // ...mapActions("cart", ["emptyCart", "deleteCartRow", "checkout"]),
    async emptyCart() {
      await this.$store.dispatch("configurator/cart/emptyCart");
    },
    async deleteCartRow(id) {
      await this.$store.dispatch("configurator/cart/deleteCartRow", id);
    },
    async checkout(n) {
      await this.$store.dispatch("configurator/cart/checkout", n);
    },
    // ...mapActions("user", ["getUserCart"]),

    getUserCart() {
      this.$store.dispatch("configurator/user/getUserCart");
    },
    setSizePage(val) {
      this.pageSize = val;
    },

    setPage(val) {
      this.page = val;
    },

    checkCancRiga(id) {
      this.eliminaRiga = true;
      this.idRigaDaCancellare = id;
    },

    async cancRiga(id) {
      this.eliminaRiga = false;
      await this.deleteCartRow(id);
      this.getUserCart();
    },

    async svuotaCarrello() {
      this.elimCarrello = false;
      await this.emptyCart();
      this.getUserCart();
    },

    async confermaOrdine() {
      this.disabled = true;
      try {
        // Chiama la funzione di checkout per confermare l'ordine
        await this.checkout(this.note);
        this.getUserCart();
        this.confirmAdd = true;
        this.disabled = false;
      } catch (error) {
        console.error("Errore durante la conferma dell'ordine:", error);
        this.disabled = false;
      }
    },

    personaliz(id) {
      if (_.isEmpty(this.pers)) {
        this.pagedCartRows.forEach((e) => {
          if (e.id == id) {
            console.log("TRANSLATING", this.$options.filters.capitalize(e.colore_nome))
            this.pers.push(
              {
                label: this.$t("message.frontale").toUpperCase(),
                nome: e.nome,
                coloreTesto: this.$t("message." + this.$options.filters.capitalize(e.colore_nome.replace("-", " "))),
                fontTesto: e.font_nome,
              },
              {
                label: "ESTERNO ASTA SINISTRA",
                nome: e.nomeExtAstaSx,
                coloreTesto: e.coloreNomeExtAstaSx,
                fontTesto: e.fontNomeExtAstaSx,
              },
              {
                label: "INTERNO ASTA SINISTRA",
                nome: e.nomeIntAstaSx,
                coloreTesto: e.coloreNomeIntAstaSx,
                fontTesto: e.fontNomeIntAstaSx,
              },
              {
                label: "ESTERNO ASTA DESTRA",
                nome: e.nomeExtAstaDx,
                coloreTesto: e.coloreNomeExtAstaDx,
                fontTesto: e.fontNomeExtAstaDx,
              },
              {
                label: "INTERNO ASTA DESTRA",
                nome: e.nomeIntAstaDx,
                coloreTesto: e.coloreNomeIntAstaDx,
                fontTesto: e.fontNomeIntAstaDx,
              }
            );
          }
        });
      }
      this.persDialog = true;
    },

    goToConfigurator() {
      this.confirmAdd = false;
      this.$router.push("/");
    },

    goToStorico() {
      this.confirmAdd = false;
      this.$store.dispatch("configurator/user/getUserHistory");
      this.$router.push("/storico");
      this.getUserCart();
    },

    getRowClass(data) {
      return data.row.nome === "" ? "hidden" : "";
    },

    setDettaglioOrdineLente(row) {
      // if (row.lensright != "" && row.lensleft != "") {
      //   if (this.dettaglioOrdineLente.length == 0) {
      //     // TODO: inserire tipo CartRow al posto di any quando inseriremo il campo id su CartRow su cfg-base-app
      //     this.pagedCartRows.forEach((e: any) => {
      //       if (e.id == row.id) {
      //         this.dettaglioOrdineLente.push(
      //           {
      //             label: (
      //               this.$t("message.Lente_destra") as string
      //             ).toUpperCase(),
      //             lens: e.lensright,
      //             lensModel: e.lensrightdescr,
      //             reference: this.activeConfiguration.note,
      //             notes: e.note,
      //             lensestype: "MONOFOCALE",
      //             sfera: e.sphright,
      //             cilindro: e.cylright,
      //             asse: e.axisright,
      //             semiDist: e.pdright,
      //             altPup: e.heightright,
      //             add: e.addright,
      //           },
      //           {
      //             label: (
      //               this.$t("message.Lente_sinistra") as string
      //             ).toUpperCase(),
      //             lens: e.lensleft,
      //             lensModel: e.lensleftdescr,
      //             reference: this.activeConfiguration.note,
      //             notes: e.note,
      //             lensestype: "MONOFOCALE",
      //             sfera: e.sphleft,
      //             cilindro: e.cylleft,
      //             asse: e.axisleft,
      //             semiDist: e.pdleft,
      //             altPup: e.heightleft,
      //             add: e.addleft,
      //           }
      //         );
      //       }
      //     });
      //   }
      //   this.detailsLensDialog = true;
      // } else

      if (row.lenssun != "") {
        let ls = this.lentiSole.filter((l) => {
          return l.ItemCode == row.lenssun;
        });

        if (this.dettaglioOrdineLente.length == 0) {
          this.pagedCartRows.forEach((e) => {
            if (e.id == row.id) {
              this.dettaglioOrdineLente.push({
                label: "LENTE SOLE",
                lens: e.lenssun,
                lensModel: ls[0].ItemName,
                // reference: e.note,
                // notes: "",
                // lensestype: "",
                // sfera: 0,
                // cilindro: 0,
                // asse: 0,
                // semiDist: 0,
                // altPup: 0,
                // add: 0,
              });
            }
          });
        }
        this.detailsLensDialog = true;
      }
    },
  },
  computed: {
    // ...mapState("cart", ["cartRows"]),
    cartRows() {
      let cartRows = this.$store.state.configurator.cart.cartRows;
        // All temples are named "CLARO": change its name into siju
      cartRows = cartRows.map((v) => {
        v["nome_asta"] = v["nome_asta"].replace("CLARO", "SU:JI");
        return v;
      });
      return cartRows;
    },
    // ...mapGetters("cart", ["rowNumber"]),
    rowNumber() {
      return this.$store.getters("configurator/cart/rowNumber");
    },
    pagedCartRows() {
      return this.filteredFront.slice(
        this.pageSize * this.page - this.pageSize,
        this.pageSize * this.page
      );
    },
    heightTableBody() {
      return this.pageSize * 60 + 71 + 1; // 60 è l'altezza delle righe della tabella impostata nel row-style, 71 è l'altezza dell'header
    },
    queryCart() {
      return jslinq(this.cartRows);
    },

    filteredFront() {
      if (this.cerca && this.cerca != "") {
        return this.queryCart
          .where((c) => {
            return c.codice_modello.indexOf(this.cerca) >= 0;
          })
          .toList();
      } else {
        return this.cartRows;
      }
    },
    lentiSole() {
      return this.$store.getters["configurator/lenti/sunLenses"];
    },
  },

  watch: {
    persDialog(nVal) {
      if (nVal == false) {
        this.pers = [];
      }
    },
    detailsLensDialog(newV) {
      if (newV == false) {
        this.dettaglioOrdineLente = [];
      }
    },
  },
};
</script>

<style lang="less">
#carrello {
  max-height: 100vh;
  height: 100vh;

  @media (max-width: 1200px) {
    .cerca-modello {
      div {
        top: 70%;
      }
    }
  }
  @media (min-width: 1201px) {
    .cerca-modello {
      div {
        top: 80%;
      }
    }
  }

  @media (max-width: 767px) {
    .cerca-modello {
      width: 86%;
    }
  }
  @media (min-width: 768px) {
    .cerca-modello {
      width: 20%;
    }
  }
  .cerca-modello {
    .el-input__inner {
      background-color: @--background-color-base;
    }
    padding: 25px;
    // width: 20%;
    height: 20vh;
  }

  @media (max-width: 1200px) {
    .tabella-carrello {
      height: 40vh;
    }
  }
  @media (min-width: 1201px) {
    .tabella-carrello {
      height: 45vh;
    }
  }

  .tabella-carrello {
    .el-table__body-wrapper {
      background-color: #fff;
    }
    .pagination {
      width: 100%;
      height: auto;
      min-height: 50px;
      .el-pagination {
        margin: 20px 0px;
        float: right;
        color: @--color-primary;
      }
    }

    .cart-table {
      background-color: @--table-header-background-color;
      height: 100% !important;

      .el-table__header,
      .el-table__body {
        th {
          color: @--color-primary !important;
        }
        div {
          word-break: normal !important;
        }
      }

      .el-table__empty-text {
        color: @--color-primary;
        font-weight: bold;
        font-size: 16px;
      }
    }
  }

  @media (max-width: 1200px) {
    .note-text {
      margin-top: 0px;
      span {
        position: relative;
        top: 50px;
      }
    }
  }

  @media (max-width: 992px) {
    .note-text {
      margin-top: 100px;
      span {
        position: relative;
        top: -5px;
      }
    }
  }
  @media (min-width: 1201px) {
    .note-text {
      margin-top: 10px;
      span {
        position: relative;
        top: 40px;
      }
    }
  }

  .note-text {
    // margin-top: 100px;
    margin-right: 100px;
    margin-left: 100px;
    height: 20vh;

    .el-textarea__inner {
      border-radius: 0;
      background-color: @--background-color-base;
    }

    span {
      float: left;
      color: @--color-warning;
      font-weight: 900;
    }
  }

  .footer-button {
    // background-color: @--color-primary;
    height: 11vh;
    width: 100%;

    .buttons-group {
      width: 100%;
      position: absolute;
      right: 0;
      bottom: 0;

      .svuota-carrello {
        width: 50%;
        // font-weight: 900;/
      }
      .conferma-ordine {
        width: 50%;
        // font-weight: 900;
        // background-color: @--color-black;
        // color: @--color-white;
      }
    }
    .el-button {
      // background-color: @--color-primary;
      // border-color: @--color-white;
      // color: @--color-white;
      border-radius: 0px;
      // width: 50%;
      height: 55px;
      padding: 0px;
    }
  }

  .details-dialog {
    background-color: rgba(0, 0, 0, 0.59);
    padding-top: 5%;
  }

  .history-table {
    background-color: @--table-header-background-color;
    .el-table__header {
      height: 71px;
      tr {
        color: @--color-warning !important;
      }
    }

    .el-table__header,
    .el-table__body {
      div {
        word-break: normal !important;
      }
    }

    .el-table__empty-text {
      color: @--color-primary;
      font-weight: bold;
      font-size: 16px;
    }
  }

  .confirm-add-dialog {
    padding: 10%;
    font-weight: 500;
    background-color: rgba(0, 0, 0, 0.59);
    .el-dialog {
      color: @--color-primary;
      border: 1px solid @--color-primary;
      box-shadow: 0 0px 0px rgb(0 0 0);
      border-radius: 0px;
      .el-dialog__header {
        margin-top: 10%;
        span {
          color: @--color-primary;
          font-size: 16px;
        }
      }
      .el-dialog__body {
        padding: 50px 0px 0px 0px;
        margin-left: -1px;
        margin-right: -2px;
        .el-button-group {
          width: 100%;
        }
      }
    }
  }
}
</style>